import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

// Инициализация i18next
i18n
    .use(HttpApi) // Подключение загрузчика переводов
    .use(LanguageDetector) // Автоматическое определение языка
    .use(initReactI18next) // Интеграция с React
    .init({
        fallbackLng: 'uk', // Язык по умолчанию
        // debug: true, // Логирование (удалите в продакшн)
        interpolation: {
            escapeValue: false, // React уже экранирует строки
        },
        detection: {
            // Отключить автоматическое определение языка
            order: ['querystring', 'cookie', 'localStorage', 'navigator'],
            caches: ['localStorage', 'cookie'],
        },
        backend: {
            loadPath: '/locales/{{lng}}.json', // Путь до файлов с переводами
        },
    });

export default i18n;
